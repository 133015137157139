Vue.component('headerReportModal', {
    data: function () {
        return {
            reportName:"",
        }
    },
    methods:{
        openReport(){
            if(this.reportName)
                this.$router.push('/report/'+this.reportName);
        }
    },
    template: `<div id="reportModal" ref="reportModal" class="modal">
                <div class="modal-content" v-if="$store.state.session">
                    <h5>{{tr('Select a Report')}}</h5>
                    <div class="row">
                        <div class="col m6" >
                            <template v-for="(report, index) in $store.getters.getReports">
                                <template v-if="index % 2==0">
                                   <p :key="reportName=report.fields.ReportName">
                                      <input name="group1" type="radio" :id="reportName=report.fields.ReportName" @change="reportName=report.fields.ReportName" />
                                      <label :for="reportName=report.fields.ReportName">{{tr(report.fields.ReportTitle)}}</label>
                                   </p>
                               </template>
                            </template>
                        </div>
                        <div class="col m6" >
                            <template v-for="(report , index) in  $store.getters.getReports">
                                <template v-if="index%2!=0">
                                    <p :key="reportName=report.fields.ReportName">
                                      <input name="group1" type="radio" :id="reportName=report.fields.ReportName" @change="reportName=report.fields.ReportName" />
                                      <label :for="reportName=report.fields.ReportName">{{tr(report.fields.ReportTitle)}}</label>
                                   </p>
                                </template>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <a href="#" class="modal-action modal-close btn-flat" @click.prevent="openReport" >{{tr("Run")}}</a>
                </div>
            </div> `
})